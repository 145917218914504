import { GridColDef, GridRenderCellParams } from '@mui/x-data-grid'
import { format, parse, parseISO } from 'date-fns'
import { handleMoneyFormat } from '../../../utils/formatMoney'
import { formatNumberWithDot } from '../../../utils/formatNumber'
import { numberToString } from '../../../utils/formatNumberToString'
import { trauncateFractionAndFormat } from '../../../utils/trauncateFractionAndFormat'
import { Box, Tooltip } from '@mui/material'
import { GetCreditsAllocationColumnsParams } from './TableData.types'
import DeleteIcon from '../../../assets/delete.svg'
import EditIcon from '../../../assets/edit.svg'

const colFunctionFormatNumber = (params: GridRenderCellParams<any, any, any>) => {
  return params.value ? formatNumberWithDot(Math.trunc(+params.value)) : '-'
}

const formatter = new Intl.NumberFormat('pt-BR', {
  minimumFractionDigits: 2,
  maximumFractionDigits: 2,
})

export const CreditsBalanceTableColumns: GridColDef[] = [
  {
    field: 'uc_numero',
    headerName: 'Número da UC',
    description: 'Número da UC',
    hideable: true,
    sortable: true,
    minWidth: 120,
    maxWidth: 160
  },
  {
    field: 'uc_nome',
    headerName: 'Nome da UC',
    description: 'Nome da UC',
    hideable: true,
    sortable: true,
    renderCell: (params: GridRenderCellParams<any, any, any>) => {
      const value = params.value ?? '-'

      return (
        <span
          style={{
            overflow: 'hidden',
            textOverflow: 'ellipsis',
          }}
          title={value}
        >
          {value}
        </span>
      )
    },
    minWidth: 320,
    maxWidth: 600
  },
  {
    field: 'geradoras_codigo',
    headerName: 'Código UC Geradoras',
    description: 'Código UC Geradoras',
    renderCell: (params: GridRenderCellParams<any, any, any>) => {
      const value = params.value ? params.value.join(',') : '-'
      return <span
        style={{
          overflow: 'hidden',
          textOverflow: 'ellipsis'
        }}
        title={value}
      >
        {value}
      </span>
    },
    hide: true,
    hideable: true,
    minWidth: 100,
    maxWidth: 160
  },
  {
    field: 'geradoras',
    headerName: 'UC Geradora',
    description: 'UC Geradora',
    renderCell: (params: GridRenderCellParams<any, any, any>) => {
      const value = params.value ? params.value.join(',') : ''

      return (
        <span
          style={{
            overflow: 'hidden',
            textOverflow: 'ellipsis',
          }}
          title={value}
        >
          {value}
        </span>
      )
    },
    minWidth: 260,
    hide: true,
    hideable: true,
  },
  {
    field: 'consumo_total_faturado_qt',
    headerName: 'Energia Consumida (kWh)',
    description: 'Energia Consumida (kWh)',
    renderCell: (params: GridRenderCellParams<any, any, any>) => {
      return params.value ? Number(params.value.toFixed(0)).toLocaleString('pt-BR') : '-'
    },
    hideable: true,
    sortable: true,
    minWidth: 120,
    maxWidth: 180
  },
  {
    field: 'energia_comp_total_qt',
    headerName: 'Energia Compensada (kWh)',
    description: 'Energia Compensada (kWh)',
    renderCell: (params: GridRenderCellParams<any, any, any>) => {
      return params.value ? Math.abs(Number(params.value.toFixed(0))).toLocaleString('pt-BR') : '-'
    },
    hideable: true,
    minWidth: 120,
    maxWidth: 180
  },
  {
    field: 'consumo_total_faturado_val',
    headerName: 'Energia Consumida (R$)',
    description: 'Energia Consumida (R$)',
    hide: true,
    renderCell: (params: GridRenderCellParams<any, any, any>) => {
      return params.value ? handleMoneyFormat(params.value) : '-'
    },
    hideable: true,
    minWidth: 120,
    maxWidth: 180
  },
  {
    field: 'energia_comp_total_val',
    headerName: 'Energia Compensada (R$)',
    description: 'Energia Compensada (R$)',
    hide: true,
    renderCell: (params: GridRenderCellParams<any, any, any>) => {
      return params.value ? handleMoneyFormat(params.value) : '-'
    },
    hideable: true,
    minWidth: 120,
    maxWidth: 180
  },
  {
    field: 'energia_recebida',
    headerName: 'Energia Recebida (kWh)',
    description: 'Energia Recebida (kWh)',
    hide: true,
    renderCell: (params: GridRenderCellParams<any, any, any>) => {
      return params.value ? handleMoneyFormat(params.value) : '-'
    },
    hideable: true,
    minWidth: 120,
    maxWidth: 180
  },
  {
    field: 'mes_de_consumo',
    headerName: 'Mês de Consumo (Injetada)',
    description: 'Mês de Consumo (Injetada)',
    hide: true,
    renderCell: (params: GridRenderCellParams<any, any, any>) => {
      return params.value ? format(parseISO(params.value), 'MM/yyyy') : '-'
    },
    hideable: true,
    minWidth: 120,
    maxWidth: 180
  },
  {
    field: 'remuneracao_gerador',
    headerName: 'Faturamento (R$)',
    description: 'Faturamento (R$)',
    renderCell: (params: GridRenderCellParams<any, any, any>) => {
      return params.value ? handleMoneyFormat(params.value) : '-'
    },
    hideable: true,
    sortable: true,
    minWidth: 120,
    maxWidth: 180
  },
  {
    field: 'economia',
    headerName: 'Economia do Consumidor (R$)',
    description: 'Economia do Consumidor (R$)',
    renderCell: (params: GridRenderCellParams<any, any, any>) => {
      return params.value ? handleMoneyFormat(params.value) : '-'
    },
    hideable: true,
    sortable: true,
    minWidth: 120,
    maxWidth: 180
  },
  {
    field: 'cosip',
    headerName: 'Cosip (R$)',
    description: 'Cosip (R$)',
    sortable: true,
    hide: true,
    renderCell: (params: GridRenderCellParams<any, any, any>) => {
      return params.value ? handleMoneyFormat(params.value) : '-'
    },
    hideable: true,
    minWidth: 120,
    maxWidth: 180
  },
  {
    field: 'consumo_p_qt',
    headerName: 'Consumo Ponta (kWh)',
    description: 'Consumo Ponta (kWh)',
    sortable: true,
    hide: true,
    renderCell: (params: GridRenderCellParams<any, any, any>) => {
      return params.value ? Number(params.value.toFixed(0)).toLocaleString('pt-BR') : '-'
    },
    hideable: true,
    minWidth: 120,
    maxWidth: 180
  },
  {
    field: 'consumo_fp_spt',
    headerName: 'Consumo fp/spt',
    description: 'Consumo fp/spt',
    sortable: true,
    hide: true,
    renderCell: (params: GridRenderCellParams<any, any, any>) => {
      return params.value ? Number(params.value.toFixed(0)).toLocaleString('pt-BR') : '-'
    },
    hideable: true,
    minWidth: 120,
    maxWidth: 180
  },
  {
    field: 'saldo_de_creditos',
    headerName: 'Saldo de Créditos (kWh)',
    description: 'Saldo de Créditos (kWh)',
    renderCell: (params: GridRenderCellParams<any, any, any>) => {
      return params.value ? Number(params.value.toFixed(0)).toLocaleString('pt-BR') : '-'
    },
    hideable: true,
    sortable: true,
    minWidth: 120,
    maxWidth: 180,
  },
  {
    field: 'demanda_faturada_p_qt',
    headerName: 'Demanda p',
    description: 'Demanda p',
    sortable: true,
    hide: true,
    renderCell: (params: GridRenderCellParams<any, any, any>) => {
      return params.value ? Number(params.value.toFixed(0)).toLocaleString('pt-BR') : '-'
    },
    hideable: true,
    minWidth: 120,
    maxWidth: 180
  },
  {
    field: 'demanda_fp_spt',
    headerName: 'Demanda fp/spt',
    description: 'Demanda fp/spt',
    sortable: true,
    hide: true,
    renderCell: (params: GridRenderCellParams<any, any, any>) => {
      return params.value ? Number(params.value.toFixed(0)).toLocaleString('pt-BR') : '-'
    },
    hideable: true,
    minWidth: 120,
    maxWidth: 180
  },
  {
    field: 'percentual_suprido',
    headerName: 'Percentual Suprido',
    description: 'Percentual Suprido',
    sortable: true,
    hide: true,
    renderCell: (params: GridRenderCellParams<any, any, any>) => {
      return params.value ? numberToString(params.value * 100, 2) + '%' : '-'
    },
    hideable: true,
    minWidth: 120,
    maxWidth: 180
  },
  {
    field: 'fator_ponta',
    headerName: 'Fator Ponta',
    description: 'Fator Ponta',
    sortable: true,
    hide: true,
    renderCell: (params: GridRenderCellParams<any, any, any>) => {
      return params.value ? numberToString(params.value * 100, 2) + '%' : '-'
    },
    hideable: true,
    minWidth: 120,
    maxWidth: 180
  },
  {
    field: 'grupo',
    headerName: 'Grupo',
    description: 'Grupo',
    sortable: true,
    hide: true,
    renderCell: (params: GridRenderCellParams<any, any, any>) => {
      return params.value ? params.value : '-'
    },
    hideable: true,
    minWidth: 60,
    maxWidth: 100
  },

  {
    field: 'icms_aliq',
    headerName: 'Aliquota ICMS (%)',
    description: 'Aliquota ICMS (%)',
    sortable: true,
    hide: true,
    renderCell: (params: GridRenderCellParams<any, any, any>) => {
      return params.value
        ? trauncateFractionAndFormat(formatter.formatToParts(params.value * 100), 2)
        : '-'
    },
    hideable: true,
    minWidth: 120,
    maxWidth: 180
  },
  {
    field: 'pis_aliq',
    headerName: 'Aliquota PIS (%)',
    description: 'Aliquota PIS (%)',
    sortable: true,
    hide: true,
    renderCell: (params: GridRenderCellParams<any, any, any>) => {
      return params.value
        ? trauncateFractionAndFormat(formatter.formatToParts(params.value * 100), 2)
        : '-'
    },
    hideable: true,
    minWidth: 120,
    maxWidth: 180
  },
  {
    field: 'cofins_aliq',
    headerName: 'Aliquota COFINS (%)',
    description: 'Aliquota COFINS (%)',
    sortable: true,
    hide: true,
    renderCell: (params: GridRenderCellParams<any, any, any>) => {
      return params.value
        ? trauncateFractionAndFormat(formatter.formatToParts(params.value * 100), 2)
        : '-'
    },
    hideable: true,
    minWidth: 120,
    maxWidth: 180
  },
  {
    field: 'data_leitura_anterior',
    headerName: 'Data Leitura Anterior',
    description: 'Data Leitura Anterior',
    sortable: true,
    hide: true,
    renderCell: (params: GridRenderCellParams<any, any, any>) => {
      if (!params.value) return '-'

      return format(new Date(params.value), 'dd/MM/yyyy')
    },
    hideable: true,
    minWidth: 120,
    maxWidth: 180
  },
  {
    field: 'data_leitura_atual',
    headerName: 'Data Leitura Atual',
    description: 'Data Leitura Atual',
    sortable: true,
    hide: true,
    renderCell: (params: GridRenderCellParams<any, any, any>) => {
      if (!params.value) return '-'

      return format(new Date(params.value), 'dd/MM/yyyy')
    },
    minWidth: 120,
    maxWidth: 180
  },
  {
    field: 'dias_faturados',
    headerName: 'Dias Faturados',
    description: 'Dias Faturados',
    sortable: true,
    hide: true,
    renderCell: (params: GridRenderCellParams<any, any, any>) => {
      return params.value ? params.value : '-'
    },
    hideable: true,
    minWidth: 80,
    maxWidth: 180
  },
  {
    field: 'data_proxima_leitura',
    headerName: 'Data Próxima Leitura',
    description: 'Data Próxima Leitura',
    sortable: true,
    hide: true,
    renderCell: (params: GridRenderCellParams<any, any, any>) => {
      if (!params.value) return '-'

      return format(new Date(params.value), 'dd/MM/yyyy')
    },
    hideable: true,
    minWidth: 120,
    maxWidth: 180
  },
  {
    field: 'data_emissao',
    headerName: 'Data Emissão',
    description: 'Data Emissão',
    sortable: true,
    hide: true,
    renderCell: (params: GridRenderCellParams<any, any, any>) => {
      if (!params.value) return '-'

      return format(new Date(params.value), 'dd/MM/yyyy')
    },
    hideable: true,
    minWidth: 120,
    maxWidth: 180,
  },
  {
    field: 'vencimento',
    headerName: 'Data Vencimento',
    description: 'Data Vencimento',
    sortable: true,
    hide: true,
    renderCell: (params: GridRenderCellParams<any, any, any>) => {
      if (!params.value) return '-'

      return format(new Date(params.value), 'dd/MM/yyyy')
    },
    hideable: true,
    minWidth: 120,
    maxWidth: 180
  },
  {
    field: 'modalidade_de_compensacao',
    headerName: 'Modalidade de compensação',
    description: 'Modalidade de compensação',
    hide: true,
    renderCell: (params: GridRenderCellParams<any, any, any>) => {
      if (params.value === 'GERACAO_COMPARTILHADA') return 'Geração Compartilhada'
      if (params.value === 'AUTOCONSUMO_LOCAL') return 'Autoconsumo Local'
      if (params.value === 'AUTOCONSUMO_REMOTO') return 'Autoconsumo Remoto'
      if (params.value === 'EMUC') return 'EMUC'
      if (!params.value) return '-'
    },
    hideable: true,
    minWidth: 200
  },
  {
    field: 'med_ger_tot_quant',
    headerName: 'Quantidade total de medição de gerador',
    hide: true,
    hideable: true,
    minWidth: 200
  },
]

export const CreditsAuditoryTableColumns = [
  {
    field: 'uc_numero',
    headerName: 'Número da UC',
    description: 'Número da UC',
    hideable: true,
    sortable: true,
    minWidth: 120,
    maxWidth: 180
  },
  {
    field: 'uc_nome',
    headerName: 'Nome da UC',
    description: 'Nome da UC',
    hideable: true,
    sortable: true,
    renderCell: (params: GridRenderCellParams<any, any, any>) => {
      const value = params.value ?? '-'

      return (
        <span
          style={{
            overflow: 'hidden',
            textOverflow: 'ellipsis',
          }}
          title={value}
        >
          {value}
        </span>
      )
    },
    minWidth: 340
  },
  {
    field: 'consumo_total_qt',
    headerName: 'Consumo (kWh)',
    description: 'Consumo (kWh)',
    renderCell: colFunctionFormatNumber,
    minWidth: 80
  },
  {
    field: 'energia_comp_total_qt',
    headerName: 'Compensação (kWh)',
    renderCell: colFunctionFormatNumber,
    minWidth: 80
  },
  {
    field: 'cota_atual',
    headerName: 'Rateio Vigente (%)',
    description: 'Rateio Vigente (%)',
    renderCell: (params: GridRenderCellParams<any, any, any>) => {
      return params.value
        ? trauncateFractionAndFormat(formatter.formatToParts(params.value * 100), 2)
        : '-'
    },
    minWidth: 80
  },
  {
    field: 'injecao_prevista',
    headerName: 'Injeção Prevista (kWh)',
    description: 'Injeção Prevista (kWh)',
    renderCell: colFunctionFormatNumber,
    minWidth: 80
  },
]

export const getCreditsAllocationColumns = ({
  allocations,
  deleteHandler,
  editHandler,
  permissions
}: GetCreditsAllocationColumnsParams): GridColDef[] => {
  const rows: GridColDef[] = [
    {
      field: 'uc_numero',
      headerName: 'Número da UC',
      description: 'Número da UC',
      maxWidth: 120,
      sortable: true,
      hideable: true
    },
    {
      field: 'uc_nome',
      headerName: 'Nome da UC',
      description: 'Nome da UC',
      minWidth: 340,
      sortable: true,
      hideable: true
    },
    {
      field: 'consumo_total_qt',
      headerName: 'Energia Consumida (kWh)',
      description: 'Energia Consumida (kWh)',
      renderCell: colFunctionFormatNumber,
      sortable: true,
      hideable: true
    },
    {
      field: 'energia_comp_total_qt',
      headerName: 'Energia Compensada (kWh)',
      description: 'Energia Compensada (kWh)',
      renderCell: colFunctionFormatNumber,
      sortable: true,
      hideable: true
    },
    {
      field: 'creditos_estoque_tot',
      headerName: 'Saldo de Créditos (kWh)',
      description: 'Saldo de Créditos (kWh)',
      renderCell: colFunctionFormatNumber,
      sortable: true,
      hideable: true
    },
    {
      field: 'cota_anterior',
      headerName: 'Rateio Mês Anterior (%)',
      description: 'Rateio Mês Anterior (%)',
      hide: true,
      sortable: true,
      hideable: true,
      renderCell: (params: GridRenderCellParams<any, any, any>) => {
        return params.value
          ? trauncateFractionAndFormat(formatter.formatToParts(params.value * 100), 2)
          : '-'
      },
    },
    {
      field: 'cota_atual',
      headerName: 'Rateio Vigente (%)',
      description: 'Rateio Vigente (%)',
      sortable: true,
      hideable: true,
      renderCell: (params: GridRenderCellParams<any, any, any>) => {
        return params.value
          ? trauncateFractionAndFormat(formatter.formatToParts(params.value * 100), 2)
          : '-'
      },
    },
    {
      field: 'estoque_em_meses',
      headerName: 'Saldo em Meses',
      description: 'Saldo em Meses',
      sortable: true,
      hideable: true,
      renderCell: (params: GridRenderCellParams<any, any, any>) => {
        return params.value ? Number(params.value.toFixed(1)).toLocaleString('pt-BR') : '-'
      },
    }
  ]

  if (permissions && (permissions?.hasDeletePermission || permissions?.hasEditPermission)) {
    const actionsRow = {
      field: 'actions',
      headerName: 'Opções',
      renderHeader: () => '',
      sortable: false,
      hideable: false,
      width: 125,
      renderCell(params: Record<'row', Record<string, unknown>>) {
        const fontSize = 15;

        return (
          <Box display={'flex'} gap={2}>
            {permissions?.hasEditPermission && (
              <Tooltip title={<p style={{ fontSize }}>Editar</p>} placement='bottom'>
                <img
                  src={EditIcon.toString()}
                  onClick={() => editHandler(params.row?.id as number, allocations)}
                  style={{ cursor: 'pointer' }}
                />
              </Tooltip>
            )}

            {permissions?.hasDeletePermission && (
              <Tooltip title={<p style={{ fontSize }}>Deletar</p>} placement='bottom'>
                <img
                  src={DeleteIcon.toString()}
                  onClick={() => deleteHandler(params.row?.id as number)}
                  style={{ cursor: 'pointer' }}
                />
              </Tooltip>
            )}
          </Box>
        )
      },
    }

    rows.push(actionsRow)
  }

  return rows
}
