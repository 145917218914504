import { Link } from 'react-router-dom'

export const pathNamesHeader = {
  '/dashboard/generator': {
    name: 'Dashboard Gerador',
    path: (
      <>
        <Link to='/dashboard/generator'>Dashboard</Link>
        {' > '}
        <Link style={{ borderBottom: '0.5px solid #808080' }} to='/dashboard/generator'>
          Dashboard Gerador
        </Link>
      </>
    ),
  },
  '/dashboard/consumer': {
    name: 'Dashboard Consumidor',
    path: (
      <>
        <Link to='/dashboard/consumer'>Dashboard</Link>
        {' > '}
        <Link style={{ borderBottom: '0.5px solid #808080' }} to='/dashboard/consumer'>
          Dashboard Consumidor
        </Link>
      </>
    ),
  },
  '/credits/balance': {
    name: 'Balanço mensal',
    path: (
      <>
        <Link to='/credits/balance'>Créditos</Link>
        {' > '}
        <Link style={{ borderBottom: '0.5px solid #808080' }} to='/credits/balance'>
          Balanço mensal
        </Link>
      </>
    ),
  },
  '/credits/allocation': {
    name: 'Alocação de Créditos',
    path: (
      <>
        <Link to='/credits/allocation'>Créditos</Link>
        {' > '}
        <Link style={{ borderBottom: '0.5px solid #808080' }} to='/credits/allocation'>
          Alocação de Créditos
        </Link>
      </>
    ),
  },
  '/credits/auditory': {
    name: 'Auditoria de Créditos',
    path: (
      <>
        <Link to='/credits/auditory'>Créditos</Link>
        {' > '}
        <Link style={{ borderBottom: '0.5px solid #808080' }} to='/credits/auditory'>
          Auditoria de Créditos
        </Link>
      </>
    ),
  },
  '/invoices/distributor': {
    name: 'Faturas',
    path: (
      <>
        <Link to='/invoices/distributor'>Faturas</Link>
        {' > '}
        <Link style={{ borderBottom: '0.5px solid #808080' }} to='/invoices/distributor'>
          Distribuidora
        </Link>
      </>
    ),
  },
  '/invoices/location': {
    name: 'Faturas',
    path: (
      <>
        <Link to='/invoices/location'>Faturas</Link>
        {' > '}
        <Link style={{ borderBottom: '0.5px solid #808080' }} to='/invoices/location'>
          Locação
        </Link>
      </>
    ),
  },
  '/invoices/history': {
    name: 'Faturas',
    path: (
      <>
        <Link to='/invoices/distributor'>Faturas</Link>
        {' > '}
        <Link style={{ borderBottom: '0.5px solid #808080' }} to='/invoices/history'>
          Histórico de faturas
        </Link>
      </>
    ),
  },
  '/invoices/consumer/history': {
    name: 'Faturas',
    path: (
      <>
        <Link to='/invoices/consumer/history'>Faturas</Link>
        {' > '}
        <Link style={{ borderBottom: '0.5px solid #808080' }} to='/invoices/consumer/history'>
          Histórico de faturas
        </Link>
      </>
    ),
  },
  '/my-units/register': {
    name: 'Cadastro',
    path: (
      <>
        <Link to='/my-units/power-plant'>Minhas unidades</Link>
        {' > '}
        <Link style={{ borderBottom: '0.5px solid #808080' }} to='/my-units'>
          Cadastro
        </Link>
      </>
    ),
  },
  '/my-units/power-plant': {
    name: 'Usinas',
    path: (
      <>
        <Link to='/my-units/power-plant'>Minhas unidades</Link>
        {' > '}
        <Link style={{ borderBottom: '0.5px solid #808080' }} to='/my-units/power-plant'>
          Usinas
        </Link>
      </>
    ),
  },
  '/my-units/consumer': {
    name: 'Consumidores',
    path: (
      <>
        <Link to='/my-units/power-plant'>Minhas unidades</Link>
        {' > '}
        <Link style={{ borderBottom: '0.5px solid #808080' }} to='/my-units/consumer'>
          Consumidores
        </Link>
      </>
    ),
  },
  '/my-units/consumer-units': {
    name: 'Unidades Consumidoras',
    path: (
      <>
        <Link to='/my-units/power-plant'>Minhas unidades</Link>
        {' > '}
        <Link style={{ borderBottom: '0.5px solid #808080' }} to='/my-units/consumer-units'>
          Unidades Consumidoras
        </Link>
      </>
    ),
  },
  '/settings': {
    name: 'Configurações da conta',
    path: (
      <>
        <Link to='/settings'>Configurações da conta</Link>
      </>
    ),
  },
  '/payments': {
    name: 'Faturamentos',
    path: (
      <>
        <Link to='/payments'>Faturamentos</Link>
      </>
    ),
  },
  '/payments/aggregated': {
    name: 'Faturamentos Agrupados',
    path: (
      <>
        <Link to='/payments'>Faturamentos Agrupados</Link>
      </>
    ),
  },
  '/billingcontrol': {
    name: 'Controle de faturamento',
    path: (
      <>
        <Link to='/billingcontrol'>Controle de faturamento</Link>
      </>
    ),
  },
  '/conferences': {
    name: 'Conferencia de dados',
    path: (
      <>
        <Link to='/conferences'>Dados de faturas</Link>
      </>
    ),
  },
  '/conferences/charts': {
    name: 'Conferencia de dados',
    path: (
      <>
        <Link to='/conferences/charts'>Dados de faturas em gráfico</Link>
      </>
    ),
  },
  '/invoice-parser': {
    name: 'Upload de faturas',
    path: (
      <>
        <Link to='/invoice-parser'>Upload de faturas em massa</Link>
      </>
    ),
  },
  '/invoices/validation': {
    name: 'Validação de Faturas',
    path: (
      <>
        <Link to='/invoices/validation'>Validação de faturas</Link>
      </>
    ),
  },
  '/invoices/errors': {
    name: 'Erros de Leitor',
    path: (
      <>
        <Link to='/invoices/errors'>Erros de Leitor</Link>
      </>
    ),
  },
  '/orchestrator': {
    name: 'Orquestrador de Scrapers',
    path: (
      <>
        <Link to='/orchestrator'>Orquestrador de Scrapers</Link>
      </>
    ),
  },
  '/payment-methods': {
    name: 'Métodos de Pagamento',
    path: (
      <>
        <Link to='/payment-methods'>Métodos de Pagamento</Link>
      </>
    ),
  },
  '/bulk-update': {
    name: 'Atualizar base cadastral',
    path: (
      <>
        <Link to='/bulk-update'>Atualização em massa da base cadastral</Link>
      </>
    ),
  },
  '/payments/financial': {
    name: 'Arrecadação das Usinas',
    path: (
      <>
        <Link to='/payments/financial'>Informações detalhadas sobre a arrecadação das usinas</Link>
      </>
    ),
  },
  '/tickets': {
    name: 'Tickets',
    path: (
      <>
        <Link to='/tickets'>Tickets</Link>
      </>
    ),
  },
  '/alerts': {
    name: 'Comunicados',
    path: (
      <>
        <Link to='/alerts'>Comunicados para o cliente</Link>
      </>
    ),
  },
  '/notifications': {
    name: 'Notificações',
    path: (
      <>
        <Link to='/notifications'>Notificações</Link>
      </>
    ),
  },
  '/measurements': {
    name: 'Medições',
    path: (
      <>
        <Link to='/measurements'>Medições</Link>
      </>
    ),
  },
  '/report': {
    name: 'Faturas',
    path: (
      <>
        <Link to='/report'>Criar</Link>
      </>
    ),
  },
  '/report/edit': {
    name: 'Faturas',
    path: (
      <>
        <Link to='/report/edit'>Editar</Link>
      </>
    ),
  },
  '/demonstrative': {
    name: 'Demonstrativo',
    path: (
      <>
        <Link to='/demonstrative'>Demonstrativos</Link>
      </>
    ),
  },
  '/fees': {
    name: 'Tarifas',
    path: (
      <>
        <Link to='/fees'>Tarifas</Link>
      </>
    ),
  },
  '/create-user': {
    name: 'Criar Usuario',
    path: (
      <>
        <Link to='/create-user'>Create User</Link>
      </>
    ),
  },
  '/create-consumer': {
    name: 'Criar Gestor',
    path: (
      <>
        <Link to='/create-consumer'>Create Manager</Link>
      </>
    ),
  },
  '/permissions/users': {
    name: 'Permissões de Usuário',
    path: (
      <>
        <Link to='/permissions'>Permissões de Usuário</Link>
      </>
    ),
  },
  '/permissions/roles': {
    name: 'Grupos de Permissão',
    path: (
      <>
        <Link to='/permissions/roles'>Grupos de Permissão</Link>
      </>
    ),
  },
  '/dashboard/super': {
    name: 'Dashboard',
    path: (
      <>
        <Link to='/dashboard/super'>Dashboard</Link>
      </>
    ),
  },
}
