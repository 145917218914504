import { useEffect, useState } from "react"
import { useToast } from "../../../hooks/useToast"

import { PaginationQuery } from "../../../interfaces/pagination"
import { AlertResponse, alertsService, ListWithPagination } from "../../../services/alerts"
import { GridColDef } from "@mui/x-data-grid"

import Loading from "../../../components/Loading"
import { Button } from "../../../components/Button"
import { TableV2 } from "../../../components/TableV2"
import { TablePagination } from "../../../components/TableV2/components/TablePagination"
import { FloatingCheckboxes } from "../../../components/FloatingCheckboxes"
import { IconButton, Stack, Tooltip } from "@mui/material"
import { CreateAlertModal } from "./components/CreateAlertModal"

import DeleteIcon from '../../../assets/delete.svg'
import EditIcon from '../../../assets/edit.svg'
import { DeleteAlertModal } from "./components/DeleteAlertModal"
import { EditAlertModal } from "./components/EditAlertModal"

const AlertsPage = () => {
  const { toast } = useToast()

  const [isLoading, setIsLoading] = useState(true)

  const [pagination, setPagination] = useState<ListWithPagination['result']>(DEFAULT_PAGE_VALUE)
  const [paginationParams, setPaginationParams] = useState<PaginationQuery>({
    page: 1,
    itemsPerPage: 15,
    sort: {
      field: 'created_at',
      order: 'desc'
    },
  })

  const [isCreateMessageModalOpen, setIsCreateMessageModalOpen] = useState(false)
  const [deleteMessageId, setDeleteMessageId] = useState<number | undefined>()
  const [editMessageItem, setEditMessageItem] = useState<AlertResponse | undefined>()

  const fetchAlerts = async () => {
    try {
      if (!isLoading)
        setIsLoading(true)

      const data = await alertsService.listWithPagination({
        ...paginationParams
      })

      setPagination(data)
    } catch (err) {
      const error = err as Error

      toast({
        type: 'error',
        message: error?.message ?? 'Ocorreu um problema ao tentar buscar os dados'
      })
    } finally {
      setIsLoading(false)
    }
  }

  useEffect(() => {
    fetchAlerts()
  }, [paginationParams])

  const targetOptions = Object.entries(ALERT_OPTIONS).map(([key, value]) => ({
    id: key,
    label: value,
    checked: (paginationParams?.filter?.options as string[])?.includes(value)
  }))

  const handleUpdateFilterParams = (key: string, value: any) => {
    setPaginationParams((prev) => ({
      ...prev,
      page: 1,
      filter: {
        ...prev?.filter,
        [key]: value
      }
    }))
  }

  const handleChangePage = (page: number) =>
    setPaginationParams((previous) => ({
      ...previous,
      page,
    }))

  const handleToggleCreateMessageModal = () =>
    setIsCreateMessageModalOpen(prev => !prev)

  const handleToggleEditMessageModal = (item?: AlertResponse) =>
    setEditMessageItem(item)

  const handleToggleDeleteMessageModal = (id?: number) =>
    setDeleteMessageId(id)

  if (isLoading) {
    return <Loading />
  }

  return <>
    {isCreateMessageModalOpen && (
      <CreateAlertModal
        onSuccess={fetchAlerts}
        onClose={handleToggleCreateMessageModal}
      />
    )}
    {editMessageItem && (
      <EditAlertModal
        onSuccess={fetchAlerts}
        onClose={handleToggleEditMessageModal}
        item={editMessageItem}
      />
    )}
    {deleteMessageId && (
      <DeleteAlertModal
        onSuccess={fetchAlerts}
        onClose={handleToggleDeleteMessageModal}
        itemId={deleteMessageId}
      />
    )}

    <Stack direction='row' alignItems={'center'} justifyContent={'space-between'} marginBottom={1} marginTop={4}>
      <FloatingCheckboxes
        label='Opções'
        isFilterActive
        selectableAll
        options={targetOptions}
        submitAction={(values) =>
          handleUpdateFilterParams(
            'options',
            values
              .filter(value => value.checked)
              .map(value => value.id))
        }
      />

      <Button
        text="Novo comunicado"
        textSize={14}
        containerStyle={{ width: 'fit-content', borderRadius: 4 }}
        onClick={handleToggleCreateMessageModal}
      />
    </Stack>

    <TableV2
      alignText='center'
      columns={generateTableColumns(
        handleToggleEditMessageModal,
        handleToggleDeleteMessageModal
      )}
      rows={pagination?.data ?? []}
    >
      <TablePagination
        currentPageItemCount={pagination?.data?.length ?? 0}
        pageInfo={pagination?.pageInfo ?? DEFAULT_PAGE_VALUE?.pageInfo}
        onChange={handleChangePage}
      />
    </TableV2 >
  </>

}

export const ALERT_OPTIONS = {
  GLOBAL: "Global",
  // PER_DISTRIBUTOR: "Por distribuidor",
  // PER_USER: "Por usuario",
}

const generateTableColumns: (
  onEdit: (value?: AlertResponse) => void,
  onDelete: (value?: number) => void
) => GridColDef<any, any, unknown>[] = (onEdit, onDelete) => [
  {
    headerName: 'ID',
    field: 'id',
    maxWidth: 110
  },
  {
    headerName: 'Opção',
    field: 'option',
    maxWidth: 140
  },
  {
    headerName: 'Mensagem',
    field: 'message',
  },
  {
    headerName: 'Data de criação',
    field: 'created_at',
    maxWidth: 180,
  },
  {
    headerName: 'Expira em',
    field: 'expires_at',
    maxWidth: 180,
  },
  {
    field: 'actions',
    headerName: '',
    sortable: false,
    hideable: false,
    maxWidth: 180,
    renderCell(params) {
      return (
        <Stack gap={2} direction='row'>
          <Tooltip
            title={<p style={{ fontSize: 15 }}>Editar</p>}
            placement='bottom'
          >
            <IconButton onClick={() => onEdit(params.row as AlertResponse)}>
              <img src={EditIcon.toString()} />
            </IconButton>
          </Tooltip>
          <Tooltip title={<p style={{ fontSize: 15 }}>Deletar</p>} placement='bottom'>
            <IconButton onClick={() => onDelete(params.id as number)}>
              <img src={DeleteIcon.toString()} />
            </IconButton>
          </Tooltip>
        </Stack>
      )
    },
  }
]

const DEFAULT_PAGE_VALUE = {
  data: [],
  pageInfo: {
    limit: 0,
    totalCount: 0,
    totalPages: 0,
    currentPage: 1
  }
}

export default AlertsPage
