/* eslint-disable react/jsx-key */
import { ReactComponent as PaperIcon } from '../../assets/paper.svg'
import { ReactComponent as DocumentsIcon } from '../../assets/documents.svg'
import { ReactComponent as FeeIcon } from '../../assets/fee.svg'
import { ReactComponent as GraphsIcon } from '../../assets/graphs.svg'
import { ReactComponent as DollarIcon } from '../../assets/dollar.svg'
import { ReactComponent as TicketIcon } from '../../assets/mail.svg'
import { ReactComponent as DashIcon } from '../../assets/dash.svg'
import { ReactComponent as AppIcon } from '../../assets/app.svg'
import { ReactComponent as MeasurementClock } from '../../assets/measurement-clock.svg'
import { FC, SVGProps } from 'react'
import { PermissionType } from '../../interfaces/permissions'

export const clientSidebarLinks = [
  {
    ImgSrc: DashIcon,
    navText: 'Dashboard',
    path: '/dashboard/generator',
    permission: PermissionType.DASHBOARD_VIEW
  },
  {
    ImgSrc: MeasurementClock,
    navText: 'Medições',
    path: '/measurements',
    permission: PermissionType.MEASUREMENT_VIEW
  },
  {
    ImgSrc: GraphsIcon,
    showLinks: true,
    navText: 'Créditos',
    path: '/credits/balance',
    isLinkToggled: false,
    links: [
      {
        name: 'Balanço mensal',
        path: '/credits/balance',
        permission: PermissionType.CREDITS_BALANCE_VIEW
      },
      {
        name: 'Alocação de créditos',
        path: '/credits/allocation',
        permission: PermissionType.CREDITS_ALLOCATION_VIEW
      },
    ],
  },
  {
    ImgSrc: PaperIcon,
    showLinks: true,
    navText: 'Faturas',
    path: '/invoices',
    isLinkToggled: false,
    links: [
      {
        name: 'Distribuição',
        path: '/invoices/distributor',
        permission: PermissionType.INVOICES_DISTRIBUTOR_VIEW
      },
      {
        name: 'Locação',
        path: '/invoices/location',
        permission: PermissionType.INVOICES_LOCATION_VIEW
      },
      {
        name: 'Historico de distribuição',
        path: '/invoices/history',
        permission: PermissionType.INVOICES_HISTORY_VIEW
      },
      {
        name: 'Historico de locação',
        path: '/invoices/consumer/history',
        permission: PermissionType.INVOICES_CONSUMER_HISTORY_VIEW
      },
    ],
  },
  {
    ImgSrc: AppIcon,
    showLinks: true,
    navText: 'Minhas unidades',
    path: '/my-units/power-plant',
    isLinkToggled: false,
    links: [
      {
        name: 'Usinas',
        path: '/my-units/power-plant',
        permission: PermissionType.MY_UNITS_POWER_PLANT_VIEW
      },
      {
        name: 'Consumidores',
        path: '/my-units/consumer',
        permission: PermissionType.MY_UNITS_CONSUMER_VIEW
      },
      {
        name: 'UCs',
        path: '/my-units/consumer-units',
        permission: PermissionType.MY_UNITS_CONSUMER_UNITS_VIEW
      },
    ],
  },
  {
    ImgSrc: DollarIcon,
    showLinks: true,
    navText: 'Faturamento',
    path: '/payments',
    isLinkToggled: true,
    links: [

      {
        name: 'Economico',
        path: '/payments',
        permission: PermissionType.PAYMENTS_VIEW
      },
      { // All green
        name: 'Financeiro',
        path: '/payments/financial',
        permission: PermissionType.PAYMENTS_FINANCIAL_VIEW 
      },
      { // Alka, Atua, Tangisa
        name: 'Agrupado', 
        path: '/payments/aggregated',
        permission: PermissionType.PAYMENTS_AGGREGATED_PAYMENT_VIEW 
      }
    ],
  }
]

export const sideLinksMaster = [
  {
    ImgSrc: DashIcon,
    navText: 'Dashboard',
    path: '/dashboard/super',
  },
  {
    ImgSrc: TicketIcon,
    navText: 'Tickets',
    path: '/tickets',
    permission: null
  },
  {
    ImgSrc: TicketIcon,
    navText: 'Comunicados',
    path: '/alerts',
    permission: null
  },
  {
    ImgSrc: PaperIcon,
    showLinks: true,
    navText: 'Faturas',
    path: '/report',
    isLinkToggled: false,
    links: [
      { name: 'Criar', path: '/report', permission: null },
      { name: 'Editar', path: '/report/edit', permission: null },
      { name: 'Validação', path: '/invoices/validation', permission: null },
      { name: 'Erros de Leitor', path: '/invoices/errors', permission: null },
    ],
  },
  {
    ImgSrc: DocumentsIcon,
    navText: 'Demonstrativos',
    path: '/demonstrative',
    permission: null
  },
  {
    ImgSrc: FeeIcon,
    navText: 'Tarifas',
    path: '/fees',
    permission: null
  },
  {
    ImgSrc: DocumentsIcon,
    navText: 'Atualização em Massa',
    path: '/bulk-update',
    permission: null
  },
  {
    ImgSrc: PaperIcon,
    showLinks: true,
    navText: 'Conferencia de Faturas',
    path: '/conferences',
    isLinkToggled: false,
    links: [
      { name: 'Ver dados', path: '/conferences', permission: null },
      { name: 'Ver grafico', path: '/conferences/charts', permission: null },
    ],
  },
  {
    ImgSrc: PaperIcon,
    showLinks: true,
    navText: 'Permissões',
    path: '/permissions',
    isLinkToggled: false,
    links: [
      { name: 'Usuários', path: '/permissions/users', permission: null },
      { name: 'Grupos', path: '/permissions/roles', permission: null },
    ],
  },
  {
    ImgSrc: PaperIcon,
    navText: 'Orquestrador',
    path: '/orchestrator',
    permission: null
  },
  {
    ImgSrc: DollarIcon,
    navText: 'Métodos de Pagamento',
    path: '/payment-methods',
  },
]

export interface IRoute {
  ImgSrc: FC<SVGProps<SVGSVGElement>>
  showLinks?: boolean
  navText: string
  path: string
  isLinkToggled?: boolean
  permission?: PermissionType | null
  links?: Array<{
    name: string
    path: string
    permission?: PermissionType | null
  }>
}

const consumerSidebarLinks = [...clientSidebarLinks]
consumerSidebarLinks.splice(0, 1, { 
  ImgSrc: DashIcon,
  navText: 'Dashboard',
  path: '/dashboard/consumer',
  permission: PermissionType.DASHBOARD_VIEW
})

export const userRoutes: {
  [key: string]: Array<IRoute>
} = {
  GESTOR: clientSidebarLinks,
  NO_PAYMENTS: clientSidebarLinks,
  CONSUMIDOR: consumerSidebarLinks,
  MASTER: sideLinksMaster,
}
