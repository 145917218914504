import { useContext } from 'react'
import { Navigate, Outlet } from 'react-router-dom'
import { Sidebar } from '../components/Sidebar'
import { Header } from '../components/UI/Header'
import { GlobalContext } from '../context/global/global'
import { Content, ContentContainer, DashboardLayoutContainer } from './styles'
import { PermissionsProvider } from '../hooks/usePermissions'
import { RoutesProvider } from '../context/global/sidebar-routes'
import { Alert, Divider, Stack } from '@mui/material'

export function DashboardLayout() {
  const { state } = useContext(GlobalContext)

  const hasAlert = (state.alerts?.length ?? 0) > 0

  return (
    <>
      {!state.auth ? (
        <Navigate to='/' />
      ) : (
        <PermissionsProvider>
          <RoutesProvider>
            <DashboardLayoutContainer>
              <Sidebar />
              <ContentContainer>
                {hasAlert && (
                  <Stack>
                    {state?.alerts?.map((item, idx) => (
                      <>
                        <Alert
                          key={item?.id}
                          severity='error'
                          variant='filled'
                          sx={{ borderRadius: 0 }}
                        >
                          {item.message}
                        </Alert>
                        {(
                          (state?.alerts?.length ?? 0) > 0 &&
                          ((state?.alerts?.length ?? 0) - 1) !== idx
                        ) && (
                            <Divider key={`${item.id}-hr`} />
                          )}
                      </>
                    ))}
                  </Stack>
                )}

                <Header />
                <Content>
                  <Outlet />
                </Content>
              </ContentContainer>
            </DashboardLayoutContainer>
          </RoutesProvider>
        </PermissionsProvider>
      )}
    </>
  )
}
