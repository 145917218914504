import { InputLabel, Stack } from "@mui/material"
import { InputV2 } from "../../../../components/InputV2"
import Modal from "../../../../components/ModalV2"
import { Button } from "../../../../components/Button"
import { ALERT_OPTIONS } from ".."
import { ChangeEvent, useState } from "react"
import { SelectV2 } from "../../../../components/SelectV2"
import { useToast } from "../../../../hooks/useToast"
import { alertsService } from "../../../../services/alerts"

interface CreateAlertModalProps {
  onSuccess: () => Promise<void> 
  onClose: () => void
}

export const CreateAlertModal = ({ onClose, onSuccess }: CreateAlertModalProps) => {
  const { toast } = useToast()

  const [isLoading, setIsLoading] = useState(false)
  const [alert, setAlert] = useState<Alert>({
    option: 'GLOBAL',
    message: ''
  })

  const handleChangeAlert = <T extends string = 'option' | 'message'>(
    field: T,
    value: T extends 'option' ? keyof typeof ALERT_OPTIONS : string
  ) => {
    setAlert(prev => ({
      ...prev,
      [field]: value,
    }))
  }

  const selectOptions = Object.entries(ALERT_OPTIONS).map(([key, value]) => ({
    id: key,
    displayName: value
  }))

  const handleSubmit = async () => {
    try {
      setIsLoading(true)

      if (!alert.message?.length) {
        toast({ type: 'warning', message: 'O campo de mensagem tem que estar preenchido' })
        return
      }
      await alertsService.create(alert)

      toast({
        type: 'success',
        message: 'O comunicado foi criado com sucesso'
      })

      onClose()
    } catch {
      toast({
        type: 'error',
        message: 'Não foi possivel criar o comunicado'
      })
    } finally {
      await onSuccess()
      setIsLoading(false)
    }
  }

  return (
    <Modal.Provider
      isOpen
      maxWidth={650}
      style={{ width: "100%" }}
      onClose={onClose}
    >
      <Modal.Title>
        {'Criar comunicado'}
        <Modal.CloseButton alignLeft />
      </Modal.Title>

      <Modal.Body>
        <Stack gap={2}>
          <Stack style={{ position: 'relative' }} gap={1}>
            <InputLabel id='alert-option'>Tipo de comunicado</InputLabel>
            <SelectV2
              required
              formik={false}
              value={alert.option}
              items={selectOptions}
              onChange={({ target }) => {
                handleChangeAlert('option', target?.value as keyof typeof ALERT_OPTIONS)
              }}
            />
          </Stack>

          <InputV2
            required
            formik={false}
            label='Mensagem'
            value={alert.message}
            onChange={({ target }: ChangeEvent<HTMLInputElement>) => {
              handleChangeAlert('message', target?.value)
            }}
          />
        </Stack>

        <Stack direction={'row'} mt={3}>
          <Button
            text='Cancelar'
            onClick={onClose}
          />
          <Button
            text='Criar'
            disabled={isLoading}
            isLoading={isLoading}
            onClick={handleSubmit}
          />
        </Stack>
      </Modal.Body>
    </Modal.Provider>
  )
}

interface Alert {
  option: keyof typeof ALERT_OPTIONS
  message: string
}
